import api from '@/utils/api'

export function collect(params) {
  return api.get('activity/v1/collect', { params:params })
}

export function vote(params) {
  return api.post('activity/v1/collect/vote', params)
}

export function rank(params) {
  return api.get('activity/v1/collect/rank', { params:params })
}