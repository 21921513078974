<template>
  <div class="warpbg">
    <div class="user_header">
      <div v-if="!isLogin" class="user_login">您好，请<span @click="handleShowLogin">【登录】</span></div>
      <div v-if="isLogin" class="user_logout" >欢迎您，{{ account }} <span @click="handleLogout">【退出】</span></div>
    </div>
    <div class="frame_top">
      <div class="box_top" :class="{'box_top_last': index === 2}" v-for="(item, index) in candidatesList.slice(0, 3)" :key="index">
        <img class="top_name_img" :src="item.name_img" alt="">
        <div class="top_from_text">出自</div>
        <img class="top_from_img" :src="item.from | filterGameNameImg" alt="">
        <div class="top_name_from">{{ item.from | filterFromName }}</div>
        <vue-seamless-scroll
          :data="[item.comment]"
          :class-option="scrollClassOption"
          class="top_name_remark_warp"
        >
          <div class="top_name_remark_text" v-for="(item, index) in [item.comment]" :key="index">{{ item | filterComment }} </div>
        </vue-seamless-scroll>
        <!-- <div class="top_name_remark">为了沙巴克为了沙巴克</div> -->
      </div>
      <!-- <div class="box_top"></div>
      <div class="box_top box_top_last"></div> -->
    </div>

    <div class="frame_rank">
      <div class="box_rank" v-for="(item, index) in candidatesList.slice(0, 5)" :key="index">
        <div class="box_from">
          <div class="from_text">出自</div>
          <img class="from_img" :src="item.from | filterGameNameImg" alt="">
        </div>
        <div class="box_rank_content">
          <img class="name_img" :src="item.name_img" alt="">
          <div class="name_from" :class="{ 'anonymous_name_from': item.from.indexOf('不愿意透露姓名的热心玩家') !== -1 }">{{ item.from | filterFromName }}</div>
          <div class="rank_ballot">
            <div class="cell_ballot_unit">票</div>
            <div class="cell_ballot_count">{{ item.ballot }}</div>
          </div>
          <div class="btn_vote" @click="handleVote(item.id)"></div>
        </div>
      </div>
      <div class="btn_more" @click="showMore = true"></div>
    </div>

    <div v-show="showMore" class="frame_more">
      <div class="box_rank_more_bg">
        <div class="box_rank_more_list">
          <div class="box_rank" v-for="(item, index) in candidatesList" :key="index">
            <div class="box_from">
              <div class="from_text">出自</div>
              <img class="from_img" :src="item.from | filterGameNameImg" alt="">
            </div>
            <div class="box_rank_content">
              <img class="name_img" :src="item.name_img" alt="">
              <div class="name_from">{{ item.from | filterFromName }}</div>
              <div class="rank_ballot">
                <div class="cell_ballot_unit">票</div>
                <div class="cell_ballot_count">{{ item.ballot }}</div>
              </div>
              <div class="btn_vote" @click="handleVote(item.id)"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="btn_more_confirm" @click="showMore = false"></div>
    </div>

    <div class="frame_desc" v-html="desc"></div>

    <!-- 投票成功奖励 -->
    <van-popup class="boxVoteSuccessPopup" v-model="showVoteSuccessPopup">
      <div class="box_cdkey">
        <div class="cell_cdkey_text">{{ cdkey}}</div>
        <div class="btn_cdkey_copy" :data-clipboard-text="cdkey" @click="handleCopyCdkey"></div>
      </div>
      <div class="btn_vote_success_confirm" @click="showVoteSuccessPopup=false"></div>
    </van-popup>
    <van-popup class="boxVotedSuccessPopup" v-model="showVotedSuccessPopup">
      <div class="box_cdkey">
        <div class="cell_cdkey_text">{{ cdkey}}</div>
        <div class="btn_cdkey_copy" :data-clipboard-text="cdkey" @click="handleCopyCdkey"></div>
      </div>
      <div class="btn_vote_success_confirm" @click="showVotedSuccessPopup=false"></div>
    </van-popup>

    <user-login
      :show="loginShow"
      :logout="logout"
      @close="handleCloseLogin"
      @login-success="handleLoginSuccess"
      @logout-success="handleLogoutSuccess"
      @input="handleLoginInput"
     />

    <game-select
      :show="gameSelectShow"
      :game-list="gameList"
      @close="gameSelectShow = false"
      @select-success="handleGameSelectSuccess"
    />
  </div>
</template>

<script>
import { collect, vote, rank } from '@/api/collect'
import { mainGame } from '@/api/user'
import vueSeamlessScroll from 'vue-seamless-scroll'
import UserLogin  from "@/components/UserLogin"
import { checkAuth, getUsername } from "@/utils/auth"
import GameSelect from '@/components/GameSelect'
import Cookies from 'js-cookie'
import Clipboard from 'clipboard'

const gameNameList = ['玛法灭世', '笑傲沙城', '血影屠龙']
const GameSlugCookieName = 'Wx-H5-Activity-Collect-GameSlug'

export default {
  components: {
    vueSeamlessScroll, userLogin: UserLogin, gameSelect: GameSelect
  },
  filters: {
    filterGameNameImg(val) {
      let ret = ''
      if (!val) {
        return ret
      }
      if (val.indexOf('玛法灭世') !== -1) {
        ret = require('@/assets/activity/collect/mfms.png');
      }
      if (val.indexOf('笑傲沙城') !== -1) {
        ret = require('@/assets/activity/collect/xasc.png');
      }
      if (val.indexOf('血影屠龙') !== -1) {
        ret = require('@/assets/activity/collect/xytl.png');
      }
      return ret
    },
    filterFromName (val) {
      let ret = '不愿意透露姓名的热心玩家'
      gameNameList.forEach(el => {
        if (val && val.split(el).length > 1) {
          if (val.split(el)[1]) {
            ret = val.split(el)[1]
          }
          
        } 
      })
      return ret
    },
    filterComment: function (value) {
      let data = value
      if (!data) {
        data = '为了沙巴克'
      }
      return data
    }
  },
  data() {
    return {
      isLogin: false,
      loginShow: false,
      account: undefined,
      gameList: [],
      gameSelectShow: false,
      logout: false,
      gameSlug: undefined,
      numberId: 1,
      desc: undefined,
      candidatesList: [],
      showMore: false,
      scrollClassOption: { limitMoveNum: 1, direction: 2, step: 0.5 },
      cdkey: undefined,
      showVoteSuccessPopup: false,
      showVotedSuccessPopup: false,
      voteState: true
    }
  },
  created() {
    this.initView()
    this.initUser()
    this.getIndex()
  },
  methods: {
    initView () {
      let scale = 1.0 / window.devicePixelRatio;
      let text = `<meta name="viewport" content="width=device-width, initial-scale=${scale}, maximum-scale=${scale}, minimum-scale=${scale}, user-scalable=no">`;
      document.write(text);
      document.documentElement.style.fontSize = window.innerWidth / 7.5 + "px";
    },
    handleMore() {
      console.log('more')
    },
    getIndex() {
      collect({ number_id: this.numberId }).then(response => {
        console.log(response)
        this.candidatesList = response.candidates
        this.desc = '<p>' + response.activity.desc.replace(/\n/g, '</p><p>') + '</p>'
      })
    },
    checkLogin() {
      let loginState = false
      if (!this.isLogin) {
        this.loginShow = true
      } else {
        loginState = true
      }
      return loginState
    },
    initUser() {
      // 登录状态
      this.isLogin = checkAuth()
      if (this.isLogin) {
        this.account = getUsername()
        console.log(this.account)
      }
      if (Cookies.get(GameSlugCookieName)) {
        this.gameSlug = Cookies.get(GameSlugCookieName)
      }
    },
    handleShowLogin() {
      this.loginShow = true
    },
    handleLogout() {
      this.logout = true
    },
    handleCloseLogin() {
      this.loginShow = false
    },
    handleLoginSuccess(user) {
      this.isLogin = true
      this.logout = false
      this.account = user.account
      this.$toast.success('登录成功')
      this.getMainGameList()
    },
    handleLogoutSuccess() {
      this.logout = false
      this.isLogin = false
      
      Cookies.remove(GameSlugCookieName)
      this.$toast.success('退出成功')
    },
    handleLoginInput(e) {
      if (e === false) {
        this.loginShow = false
      }
    },
    // 游戏选择
    getMainGameList() {
      mainGame().then(response => {
        this.gameList = response
        this.gameSelectShow = true
      })
    },
    // 游戏选中成功
    handleGameSelectSuccess(val) {
      this.gameSlug = val
      Cookies.set(GameSlugCookieName, val)
      console.log(this.gameSlug)
      if (this.gameSlug) {
        this.$toast.success('游戏确认成功')
      }
    },
    checkVote() {
      if (!this.isLogin) {
        this.loginShow = true
        return false
      } else if (!this.gameSlug) {
        this.getMainGameList()
        return false
      } else {
        return true
      }
    },
    // 投票
    handleVote(id) {
      if (this.checkVote()) {
        console.log('vote')
        if (this.voteState) {
          this.voteState = false
          vote({ id: id, game_slug: this.gameSlug }).then(response => {
            this.voteState = true
            if (!response.cdkey) {
              this.cdkey = '请联系客服领取'
            } else {
              this.cdkey = response.cdkey
            }
            if (response.first) {
              this.showVoteSuccessPopup = true
            } else {
              this.showVotedSuccessPopup = true
            }
            this.refreshRank()
          }).catch(() => {
            this.voteState = true
          })
        } else {
          this.$toast.fail('操作频繁')
        }
        
      }
    },
    // 复制用户已获得奖励列表CDKEY
    handleCopyCdkey() {
      let clipboard = new Clipboard('.btn_cdkey_copy')
      clipboard.on('success', e => {
        this.$toast.success('复制成功')
        e.clearSelection()
        clipboard.destroy() // 释放内存
      })
      clipboard.on('error', e => {
        // 不支持复制
        this.$toast.fail('该浏览器不支持自动复制')
        e.clearSelection()
        clipboard.destroy()
      })
    },
    refreshRank() {
      rank({ number_id: this.numberId }).then(response => {
        this.candidatesList = response
      })
    }
  }
}
</script>

<style>
html,
body {
  height: 100%;
  width: 100%;
  margin: 0px;
  padding: 0px;
}
.warpbg {
  width: 100%;
  height: 37.5rem;
  background: url('../../assets/activity/collect/bg.png') top center no-repeat;
  background-size: contain;
  position: relative;
}
.user_header {
  position:absolute;
  right: 0.42rem;
  top: 0.22rem;
  font-size: 0.2rem;
  color: white;
  line-height: 0.2rem;
}
.frame_top {
  width: 7rem;
  height: 4.27rem;
  margin-top: 4.93rem;
  position:absolute;
  margin-left: 0.25rem;
}
.box_top {
  float: left;
  width: 2.08rem;
  height: 4.27rem;
  background: url('../../assets/activity/collect/top_bg.png') top center no-repeat;
  background-size: contain;
  position: relative;
  margin-right: 0.38rem;
}
.box_top_last {
  margin-right: 0;
}
.top_name_img {
  margin-top: 1.08rem;
  margin-left: 0.23rem;
  width: 1.57rem;
  height: 0.34rem;
  display: block;
}
.top_from_text {
  margin-top: 0.14rem;
  font-size: 0.25rem;
  text-align: center;
  color: #FFFFFF;
}
.top_from_img {
  display: block;
  margin-top: 0.1rem;
  margin-left: 0.46rem;
  width: 1.14rem;
  height: 0.24rem;
}
.top_name_from {
  color: #FFA800;
  font-size: 0.2rem;
  width: 1rem;
  height: 1rem;
  margin: 0.15rem auto;
}
/* .top_name_remark {
  width: 1.2rem;
  font-size: 0.2rem;
  color: #FFA800;
} */
.top_name_remark_warp {
  margin-top: 0.17rem;
  height: 0.3rem;
  width: 1.76rem;
  margin: 0 auto;
  overflow: hidden;
}
.top_name_remark_text {
  
  height: 0.3rem;
  width: 100%;
  font-size: 0.2rem;
  /* text-align: center; */
  color: #FFA800;
  line-height: 0.3rem;
  margin-right: 0.15rem;
}

/* 候选排名 */
.frame_rank {
  width: 6.92rem;
  height: auto;
  position:absolute;
  margin-top: 10.78rem;
  margin-left: 0.27rem;
}
.box_rank {
  width: 6.92rem;
  height: 1.21rem;
  background: url('../../assets/activity/collect/rank_bg.png') top center no-repeat;
  background-size: contain;
  position: relative;
  margin-bottom: 0.43rem;
}
.box_from {
  margin-top: 0.16rem;
  height: 0.23rem;
  width: 100%;
  float: left;
  
}
.from_text {
  margin-left: 2.49rem;
  height: 0.23rem;
  font-size: 0.2rem;
  color: #FFFFFE;
  line-height: 0.23rem;
  float: left;
}
.from_img {
  margin-left: 0.21rem;
  float: left;
  width: 1.09rem;
  height: 0.23rem;
}
.box_rank_content {
  float: left;
  width: 100%;
  height: 0.49rem;
}
.name_img {
  float: left;
  width: 1.71rem;
  height: 0.37rem;
  margin-left: 0.34rem;
}
.name_from {
  float: left;
  color: white;
  text-align: center;
  height: 100%;
  width: 1.94rem;
  font-size: 0.2rem;
  line-height: 0.49rem;
}
.anonymous_name_from {
  text-align: left;
  line-height: normal;
}
.rank_ballot {
  float: left;
  width: 1.11rem;
  height: 100%;
}
.cell_ballot_count {
  float: right;
  height: 100%;
  line-height: 0.49rem;
  font-size: 0.4rem;
  color: #fed100;
}
.cell_ballot_unit {
  float: right;
  height: 100%;
  line-height: 0.49rem;
  font-size: 0.25rem;
  margin-left: 0.16rem;
  color: white;
}
.btn_vote {
  float: right;
  background: url('../../assets/activity/collect/btn_vote.png') top center no-repeat;
  background-size: contain;
  position: relative;
  width: 1.2rem;
  height: 100%;
  margin-right: 0.44rem;
}
.btn_more {
  width: 2.5rem;
  height: 0.56rem;
  background: url('../../assets/activity/collect/btn_more.png') top center no-repeat;
  background-size: contain;
  position: relative;
  margin: 0 auto;
}

.frame_more {
  position:absolute;
  margin-left: 0.1rem;
  margin-top: 9.62rem;
  width: 7.3rem;
  height: 9.89rem;
  background: #101010;
  border: 0.03rem solid #919191;
  border-radius: 0.2rem;
  box-sizing: border-box;
}
.box_rank_more_bg {
  width: 7rem;
  height: 8.26rem;
  background: #000000;
  margin-left: 0.12rem;
  margin-top: 0.29rem;
}
.box_rank_more_list {
  margin-left: 0.04rem;
  margin-top: 0.06rem;
  width: 6.92rem;
  height: 8.20rem;
  /* height: auto; */
  overflow-y: scroll;
}
.btn_more_confirm {
  width: 1.98rem;
  height: 0.73rem;
  margin: 0 auto;
  margin-top: 0.24rem;
  background: url('../../assets/activity/collect/btn_more_confirm.png') top center no-repeat;
  background-size: contain;
  position: relative;
}

.frame_desc {
  height: auto;
  width: 6.79rem;
  position:absolute;
  margin-top: 21.02rem;
  margin-left: 0.24rem;
  font-size: 0.25rem;
  color: #FFFFFF;
  line-height: 0.5rem;
}

/* 投票成功弹出层 */
.boxVoteSuccessPopup {
  width: 4.87rem;
  height: 2.80rem;
  background: url('../../assets/activity/collect/vote_success_bg.png') top center no-repeat;
  background-size: contain;
}
.boxVotedSuccessPopup {
  width: 4.87rem;
  height: 2.80rem;
  background: url('../../assets/activity/collect/voted_success_bg.png') top center no-repeat;
  background-size: contain;
}
.box_cdkey {
  margin-left: 0.66rem;
  margin-top: 0.87rem;
  height: 0.34rem;
  width: 3.65rem;
}
.cell_cdkey_text {
  float: left;
  background: white;
  width: 2.58rem;
  height: 100%;
  font-size: 0.2rem;
  line-height: 0.34rem;
  text-align: center;
  font-weight: bold;
}
.btn_cdkey_copy {
  float: left;
  width: 0.9rem;
  height: 100%;
  margin-left: 0.17rem;
}
.btn_vote_success_confirm {
  margin-left: 1.53rem;
  margin-top: 0.17rem;
  width: 1.81rem;
  height: 0.49rem;
}
</style>