<template>
  <div>

    <van-dialog
      class="login_dialog"
      :value="show"
      @input="$emit('input', $event)"
      title=""
      confirm-button-text="确定"
      theme="round-button"
      confirm-button-color="linear-gradient(to right, #ffa800, #ff3600)"
      :before-close="handleDialogBeforeClose"
      :closeOnClickOverlay="true"
      @open="open"
    >
      <!-- <div class="van-icon van-icon-close van-popup__close-icon van-popup__close-icon--top-right" @click="handleLoginDialogClose"></div> -->

      <van-form>
        <van-field
          readonly
          clickable
          name="picker"
          :value="value"
          label="游戏"
          placeholder="点击选择游戏"
          @click="showPicker = true"
        />
      </van-form>
    </van-dialog>

    <van-popup
      v-model="showPicker"
      position="bottom"
    >
      <van-picker
        show-toolbar
        :columns="columns"
        :loading="loading"
        @confirm="onConfirm"
        @cancel="showPicker = false"
      />
    </van-popup>
  </div>
</template>

<script>
const gameNameList = ['玛法灭世', '笑傲沙城', '血影屠龙']

export default {
  name: 'GameSelect',
  props: {
    show: {
      type: Boolean,
      default: true
    },
    gameList: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      loading: true,
      showPicker: false,
      value: undefined,
      slug: undefined,
      columns: []
    }
  },
  methods: {
    open() {
      this.columns = []
      this.value = undefined
      this.slug = undefined
      this.gameList.forEach(val => {
        if (gameNameList.includes(val.external_name)) {
          this.columns.push({
            slug: val.slug, text: val.external_name
          })
        }
      })
      // 如果只有一个默认
      if (this.columns.length === 1) {
        this.value = this.columns[0].text
        this.slug = this.columns[0].slug
      }
      this.loading = false
    },
    onConfirm (value) {
      this.value = value.text
      this.slug = value.slug
      this.showPicker = false
    },
    handleDialogBeforeClose (action, done) {
      console.log('handleDialogBeforeClose')
      if (action === 'confirm') {
        console.log('confirm')
        this.$emit('select-success', this.slug)
      } else {
        console.log('cancel')
      }
      done()
      this.$emit('close')
    }
  }
}
</script>

<style>
</style>