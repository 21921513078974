<template>
  <van-dialog class="login_dialog" :value="show" @input="$emit('input', $event)" title="玩家登录" confirm-button-text="登录" theme="round-button" 
      confirm-button-color="linear-gradient(to right, #ffa800, #ff3600)" :before-close="handleLoginDialogBeforeClose">
      <div class="van-icon van-icon-close van-popup__close-icon van-popup__close-icon--top-right" @click="handleLoginDialogClose"></div>

      <van-form ref="loginForm">
        <van-field v-model="username" label="账号" placeholder="填写账号" :rules="[{ required: true }]" />
        <van-field v-model="password" type="password" label="密码" placeholder="填写密码" :rules="[{ required: true }]"  />

      </van-form>
    </van-dialog>
</template>

<script>
import { webLogin, webLogout } from "@/api/login"
import { setUsername, removeToken, getUsername } from "@/utils/auth"
export default {
  name: 'UserLogin',
  props: {
    show: {
      type: Boolean,
      default: true
    },
    logout: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      username: undefined,
      password: undefined
    }
  },
  created() {
    this.username = getUsername()
  },
  watch: {
    logout(val) {
      if (val === true) {
        this.handleLogout()
      }
    }
  },
  methods: {
    handleLoginDialogBeforeClose(action, done) {
      if (action === 'confirm') {
        // 验证表单
        this.$refs['loginForm'].validate().then(() => {
          // 调用登录方法
          this.loginSubmit(done)
        }).catch(() => {
          done(false)
        })
      } else {
        done()
      }
    },
    handleLoginDialogClose() {
      this.$emit('close')
    },
    // 登录
    loginSubmit (done) {
      webLogin({ username: this.username, password: this.password }).then(response => {
        console.log(response)
        setUsername(response.account)
        // 登录成功
        this.$emit('login-success', response)
        done()
      }).catch(() => {
        done(false)
      })
    },
    handleLogout () {
      webLogout().then(response => {
        console.log(response)
        removeToken()
        this.$emit('logout-success')
      }).catch(() => {
        removeToken()
        this.$emit('logout-success')
      })
      
    },
  }
}
</script>

<style>

</style>